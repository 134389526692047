button.navbar-toggle-btn:focus {
    box-shadow: none;
}

.navbar-brand {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 12%;
}

.nav-logo {
    width: 1.8em;
    height: 1.8em;
}

.nav-cart-size {
    border: 1px solid red;
    position: absolute;
    height: 1em;
    width: 1em;
    top: 25%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0.1em;
    border-radius: 50%;
    background: #FF0800;
    margin: 0;
    margin-left: 0.8em;
    font-weight: bold;
}

.nav-avatar-container {
    display: flex;
    align-items: center;
    margin-left: 0.8em;
}

.nav-avatar {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: 0.2px solid white;
    filter: brightness(1.1);
}

.navbar-icons {
    font-size: 1.2em;
}

@media screen and (max-width:430px) {
    nav.navbar {
        padding: 0.5rem 0;
    }

    .nav-cart-size {
        top: 25%;
        text-align: center;
        padding: 0em;
        margin-left: 0.5em;
    }

    div.nav-mobile {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        width: 50%;
    }

    a.nav-link {
        font-size: 1rem;
    }

    .navbar-icons {
        font-size: 1em;
    }

    .navbar-dropdown-cover {
        display: none;
        visibility: hidden;
    }

    .nav-logo {
        margin-right: 0.25em;
    }

    .navbar-brand {
        width: auto;
        max-width: 50%;
    }

    .nav-avatar-container {
        margin-left: 0;
        padding: 0;
    }
}