.skeleton {
    display: flex;
    flex-flow: column nowrap;
    background: #ddd;
    margin: 10px 0;
    overflow: hidden;
}

.skeleton-product-wrapper {
    position: relative;
    margin-top: 1em;
    background: #c0c0c0;
    border-radius: 10px;
    padding: 0.5em;
    overflow: hidden;
}

.skeleton.box {
    height: 25ch;
    width: 100%;
}

.skeleton-product-wrapper .skeleton.box {
    margin-top: 0;
    margin-bottom: 10%;
    border-radius: 10px 10px 0 0;
}

.skeleton.title {
    height: 3ch;
}

.skeleton.text {
    width: 100%;
    height: 2ch;
    margin: 1em 0;
}

/* for the animations */

.shimmer-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    animation: loading 2s infinite;
}

.shimmer {
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    transform: skewX(-20deg);
    box-shadow: 0 0 30px 10px rgba(255, 255, 255, 0.05);
}

@keyframes loading {
    0% {
        transform: translateX(-150%);
    }

    50% {
        transform: translateX(50%);
    }

    100% {
        transform: translateX(150%);
    }
}