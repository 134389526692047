.social-login-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
    padding: 0;
}

.social-login-line {
    border: 0;
    width: 33%;
    height: 0;
    border-top: 1px solid #cdcdcd;
}

.social-login-content {
    padding: 0;
    margin: 0 1%;
    font-weight: 400;
    font-size: 0.9rem;
}

@media screen and (max-width: 430px) {
    .social-login-line {
        width: 30%;
    }

    .social-login-content {
        font-size: 0.8rem;
    }
}