.form-heading {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 -1em;
    margin-bottom: 1.5em;
}

.form-inner-container {
    margin: 1.5em 0;
    padding: 1em;
    padding-top: 0;
    box-shadow: 0 6px 16px 0 rgb(0 0 0 / 20%);
}

.form-heading h1 {
    width: 50%;
    text-align: center;
    margin: 0;
    font-size: 1.4em;
    font-weight: 600;
    cursor: pointer;
    border: 2px solid ghostwhite;
    border-top: 0;
    transition: all 0.2s ease-in-out;

}